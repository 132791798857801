<template>
  <va-card title="Subscribe Request">
    <va-data-table
      :fields="fields"
      :data="items"
      :per-page="20"
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          :to="{ name: 'agro-subscribe-view', params: { id: props.rowData.id } }"
          small
          color="info"
        >
          Переглянути
        </va-button>
        <va-button
          @click="remove(props.rowData.id)"
          small
          color="danger"
          class="ma-0"
        >
          Видалити
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [
        {
          name: 'email',
          title: 'email',
          width: '40%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    locale () {
      return Vue.i18n.locale()
    },
    adminUrl () {
      return process.env.VUE_APP_AGRO_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    remove (id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_AGRO_API_URL}/admin/subscribe-request/${id}?lang=${this.locale}`)
          .then(response => {
            const idx = this.items.findIndex(u => u.id === id)
            this.items.splice(idx, 1)
            this.showToast('Success')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/subscribe-request?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
